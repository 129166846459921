div {
  opacity: 1;
  transition: opacity 1s;
}

div.fade-out {
  opacity: 0;
}

/**  FIREWORKS **/
.fireworks {
  width: 100%;
  position: absolute;
  top: 0;
  height: 50%;
  z-index: 4;
}
.fireworks:before,
.fireworks:after {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px 0 var(--fc), 120px 0 var(--fc), 0 120px var(--fc),
    0 -120px var(--fc), 45px 45px var(--fc), -45px 45px var(--fc),
    85px 85px var(--fc), -85px 85px var(--fc), 45px -45px var(--fc),
    -45px -45px var(--fc), 85px -85px var(--fc), -85px -85px var(--fc),
    65px 0 var(--fc), -65px 0 var(--fc), 0 65px var(--fc), 0 -65px var(--fc),
    100px 45px var(--fc), -100px 45px var(--fc), -50px 100px var(--fc),
    50px 100px var(--fc), 100px -45px var(--fc), -100px -45px var(--fc),
    -50px -100px var(--fc), 50px -100px var(--fc), 5px 10px var(--fc);
  animation: 1s bang ease-out infinite backwards,
    1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}
.fireworks:after {
  animation-delay: 1.75s, 1.75s, 1.75s;
  animation-duration: 1.75s, 1.75s, 6.75s;
}
@keyframes bang {
  from {
    box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  }
}

@keyframes gravity {
  to {
    transform: translate(-10px, 150px);
    height: 6px;
    width: 4px;
    opacity: 0;
  }
}
@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
    --fc: var(--fc1);
  }

  20%,
  39.9% {
    margin-top: 2%;
    margin-left: 70%;
    --fc: var(--fc2);
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
    --fc: var(--fc3);
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
    --fc: var(--fc4);
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
    --fc: var(--fc5);
  }
}

.fireworks2 {
  width: 100%;
  position: absolute;
  top: 0;
  height: 50%;
  z-index: 4;
}
.fireworks:before,
.fireworks2:after {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px 0 var(--fc-2), 120px 0 var(--fc-2), 0 120px var(--fc-2),
    0 -120px var(--fc-2), 45px 45px var(--fc-2), -45px 45px var(--fc-2),
    85px 85px var(--fc-2), -85px 85px var(--fc-2), 45px -45px var(--fc-2),
    -45px -45px var(--fc-2), 85px -85px var(--fc-2), -85px -85px var(--fc-2),
    65px 0 var(--fc-2), -65px 0 var(--fc-2), 0 65px var(--fc-2),
    0 -65px var(--fc-2), 100px 45px var(--fc-2), -100px 45px var(--fc-2),
    -50px 100px var(--fc-2), 50px 100px var(--fc-2), 100px -45px var(--fc-2),
    -100px -45px var(--fc-2), -50px -100px var(--fc-2), 50px -100px var(--fc-2),
    5px 10px var(--fc-2);
  animation: 1s bang2 ease-out infinite backwards,
    1s gravity2 ease-in infinite backwards,
    5s position2 linear infinite backwards;
}
.fireworks2:after {
  animation-delay: 1.25s, 1.25s, 1.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}
@keyframes bang2 {
  from {
    box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  }
}

@keyframes gravity2 {
  to {
    transform: translate(-10px, 150px);
    height: 6px;
    width: 4px;
    opacity: 0;
  }
}
@keyframes position2 {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
    --fc-2: var(--fc6);
  }

  20%,
  39.9% {
    margin-top: 2%;
    margin-left: 70%;
    --fc-2: var(--fc7);
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
    --fc-2: var(--fc8);
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
    --fc-2: var(--fc9);
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
    --fc-2: var(--fc10);
  }
}

.fireworks3 {
  width: 100%;
  position: absolute;
  top: 0;
  height: 50%;
  z-index: 4;
}
.fireworks:before,
.fireworks3:after {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px 0 var(--fc-3), 120px 0 var(--fc-3), 0 120px var(--fc-3),
    0 -120px var(--fc-3), 45px 45px var(--fc-3), -45px 45px var(--fc-3),
    85px 85px var(--fc-3), -85px 85px var(--fc-3), 45px -45px var(--fc-3),
    -45px -45px var(--fc-3), 85px -85px var(--fc-3), -85px -85px var(--fc-3),
    65px 0 var(--fc-3), -65px 0 var(--fc-3), 0 65px var(--fc-3),
    0 -65px var(--fc-3), 100px 45px var(--fc-3), -100px 45px var(--fc-3),
    -50px 100px var(--fc-3), 50px 100px var(--fc-3), 100px -45px var(--fc-3),
    -100px -45px var(--fc-3), -50px -100px var(--fc-3), 50px -100px var(--fc-3),
    5px 10px var(--fc-3);
  animation: 1s bang3 ease-out infinite backwards,
    1s gravity3 ease-in infinite backwards,
    5s position3 linear infinite backwards;
}
.fireworks3:after {
  animation-delay: 1.45s, 1.45s, 1.45s;
  animation-duration: 1.45s, 1.45s, 6.45s;
}
@keyframes bang3 {
  from {
    box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  }
}

@keyframes gravity3 {
  to {
    transform: translate(-10px, 150px);
    height: 6px;
    width: 4px;
    opacity: 0;
  }
}
@keyframes position3 {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
    --fc-3: var(--fc11);
  }

  20%,
  39.9% {
    margin-top: 2%;
    margin-left: 70%;
    --fc-3: var(--fc12);
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
    --fc-3: var(--fc13);
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
    --fc-3: var(--fc14);
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
    --fc-3: var(--fc15);
  }
}
